import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AutoLoginGuard } from './auth/guards/auto-login.guard';
import { AuthGuard } from './auth/guards/auth.guard';
import { IntroGuard } from './auth/guards/intro.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'intro',
    pathMatch: 'full',
  },
  {
    path: 'intro',
    loadChildren: () =>
      import('./intro/intro.module').then((m) => m.IntroPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./auth/pages/login/login.module').then((m) => m.LoginPageModule),
    canLoad: [IntroGuard, AutoLoginGuard],
  },
  {
    path: 'password-reminder',
    loadChildren: () =>
      import('./auth/pages/password-reminder/password-reminder.module').then(
        (m) => m.PasswordReminderPageModule
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./auth/pages/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordPageModule
      ),
  },
  {
    path: 'confirm-email',
    loadChildren: () =>
      import('./auth/pages/confirm-email/confirm-email.module').then(
        (m) => m.ConfirmEmailPageModule
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./auth/pages/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
    canLoad: [IntroGuard],
  },
  /* main menu */
  {
    path: 'welcome',
    loadChildren: () =>
    import('./welcome/welcome.module').then( (m) => m.WelcomePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'location-list',
    loadChildren: () =>
      import('./locations/pages/location-list/location-list.module').then(
        (m) => m.LocationListPageModule
      ),
    canLoad: [AuthGuard],
  },
  /* users */
  {
    path: 'user-list',
    loadChildren: () =>
      import('./users/pages/user-list/user-list.module').then(
        (m) => m.UserListPageModule
      ),
    canLoad: [AuthGuard],
  },
  /* reports */
  {
    path: 'report-list',
    loadChildren: () =>
      import('./reports/pages/report-list/report-list.module').then(
        (m) => m.ReportListPageModule
      ),
    canLoad: [AuthGuard],
  },
  /* tags */
  {
    path: 'tag-list',
    loadChildren: () =>
      import('./devices/pages/tag-list/tag-list.module').then(
        (m) => m.TagListPageModule
      ),
    canLoad: [AuthGuard],
  },

  /* fridge */
  {
    path: 'fridge-list',
    loadChildren: () =>
      import('./devices/pages/fridge-list/fridge-list.module').then(
        (m) => m.FridgeListPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'help',
    loadChildren: () =>
      import('./help/help.module').then((m) => m.HelpPageModule),
    canLoad: [AuthGuard],
  },
  /* first call */
  {
    path: 'first-call-sheet',
    loadChildren: () =>
      import('./deceased/pages/first-call-sheet/first-call-sheet.module').then(
        (m) => m.FirstCallSheetPageModule
      ),
    canLoad: [AuthGuard],
  },
  /* search */
  {
    path: 'search',
    loadChildren: () =>
      import('./search/search.module').then((m) => m.SearchPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'deceased-list',
    loadChildren: () =>
      import('./deceased/pages/deceased-list/deceased-list.module').then(
        (m) => m.DeceasedListPageModule
      ),
    canLoad: [AuthGuard],
  },
  /* removals routes */
  {
    path: 'removals',
    loadChildren: () =>
      import('./worklist/pages/removals/removals.module').then(
        (m) => m.RemovalsPageModule
      ),
    canLoad: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
