import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'meridiemPipe',
  pure: false
})
export class MeridiemPipe implements PipeTransform {

  transform(value: number): any {
    const date = new Date(value);
    if (date.getHours() > 11) {
      return 'PM';
    }
    return 'AM';
  }
}
