import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private session: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const user = await this.session.authValue;
    if (user?.jwtToken) {
      const request = req.clone({
        setHeaders: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${user.jwtToken}`,
          accept: '*/*',
          observe: 'response',
        },
      });
      return next.handle(request).toPromise();
    } else {
      const request = req.clone({
        setHeaders: {
          'Content-Type': 'application/json; charset=UTF-8',
          accept: '*/*',
          observe: 'response',
        },
      });
      return next.handle(request).toPromise();
    }
  }
}
