export enum ApplicationRole {
    system = 1,
    administrator = 2,
    user = 3,
    viewer = 4
  }

export const applicationRoleLabel = new Map<ApplicationRole, string>([
  [ApplicationRole.system, 'System'],
  [ApplicationRole.administrator, 'Administrator'],
  [ApplicationRole.user, 'User'],
  [ApplicationRole.viewer, 'Viewer']
]);

export const applicationRoleId = new Map<string, number>([
  ['System', 1],
  ['Administrator', 2],
  ['User', 3],
  ['Viewer', 4]
]);


