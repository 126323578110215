import { TemperatureConverterPipe } from './temperature-converter.pipe';
import { PhonePipe } from './phone.pipe';
import { DayPipe } from './day.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BooleanPipe } from './boolean.pipe';
import { MonthPipe } from './month.pipe';
import { MeridiemPipe } from './meridiem.pipe';
import { FilterPipe } from './filter.pipe';


@NgModule({
  declarations: [BooleanPipe, DayPipe, FilterPipe,MeridiemPipe, MonthPipe, PhonePipe,  TemperatureConverterPipe],
  imports: [
    CommonModule
  ],
  exports: [BooleanPipe, DayPipe, FilterPipe,MeridiemPipe, MonthPipe, PhonePipe,  TemperatureConverterPipe]
})
export class PipeModule { }
