import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanPipe',
  pure: false
})
export class BooleanPipe implements PipeTransform {


  transform(value: boolean): any {
    if (value){
      return 'check_circle';
    }
    else {
      return 'cancel';
    }
  }
}
