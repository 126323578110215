import { Injectable } from '@angular/core';
import { ActivatedRoute, CanLoad, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(private readonly facade: AuthService, private router: Router, private activatedRoute: ActivatedRoute) {
  }

  async canLoad(): Promise<boolean> {
    const user = await this.facade.authValue;
    if (!user?.isAuthenticated) {
      const snapshot = this.activatedRoute.snapshot;
      this.router.navigate(['/login'], { queryParams: { returnUrl: snapshot.url, replaceUrl: true } });
      return false;
    }
    return true;
  }
}
