const mapKey = 'AIzaSyC5lP1O19noK0AVPm_qrxv1LE5Wv04hv2k';
const USER_KEY = 'DOVES_USER_KEY_V2';
const INTRO_KEY = 'DOVES_INTRO_KEY_V2';

export const environment = {
  production: false,

  //Development
  // apiUrl: 'https://localhost:5013/gateway/',
  // dovesUrl: 'https://localhost:5013/v1/',

  //Staging
  apiUrl: 'https://dovespreproduction.cloudco.technology/gateway/',
  dovesUrl: 'https://dovespreproduction.cloudco.technology/v1/',

  //Production
  // apiUrl: 'https://doves.cloudco.technology/gateway/',
  // dovesUrl:'https://doves.cloudco.technology/v1/',

  mapsKey: mapKey,
  userKey: USER_KEY,
  introKey: INTRO_KEY,
};
