import { BehaviorSubject, map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from 'src/app/library/storage.service';
import { environment } from 'src/environments/environment';

const API_AUTH = `${environment.apiUrl}authenticate`;
const API_USER = `${environment.apiUrl}user`;

export interface SessionUser {
  username: string;
  password: string;
  isAuthenticated: boolean;
  role: string;
  userId?: string;
  jwtToken?: string;
  expiryDate?: Date;
  locationId?: number;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  #authSubject: BehaviorSubject<SessionUser | null>;
  #auth: Observable<SessionUser | null>;
  #authValue: SessionUser | null = null;

  constructor(
    private readonly http: HttpClient,
    private readonly storage: StorageService
  ) {

    this.#authSubject = new BehaviorSubject<SessionUser | null>(
      this.#authValue
    );
    
    this.#auth = this.#authSubject.asObservable();

    this.storage.getUser().then((value: any) => {
      this.#authValue = value;
      this.#authSubject.next(value);
    });
  }

  /**
   * The observable to subscribe to
   */
  public get auth(): Observable<SessionUser | null> {
    return this.#auth;
  }

  /**
   * Get the auth object from the storage
   */
  public get authValue(): Promise<SessionUser | null> {
    return this.storage.getUser();
  }

  /* Auth Functions */
  //This service authenticates with our own systems.
  public login(username: string, password: string): Observable<any> {
    //return this.http.post(`${API_USER}SynDovesNet`, { username, password }).pipe(
      return this.http.post(`${API_USER}SynDovesNet`, { username, password }).pipe(
      map((response: any) => {
        if (response.result.succeeded) {
          const auth = response.auth;
          console.log(response.auth)
          const user = {
            isAuthenticated: true,
            username: auth.userName,
            role: auth.role,
            userId: auth.userId,
            jwtToken: auth.jwtToken,
            password,
          };

          this.setSession(user);
        }

        return response;
      })
    );
  }

  public register(request: any): Observable<any> {
    return this.http.post(`${API_AUTH}/Register`, request);
  }

  // public syncDovesNet(
  //   username: string,
  //   password: string,
  //   branchName: string,
  //   branchAddress: string
  // ): Observable<any> {
  //   return this.http.post(`${API_AUTH}/syncDovesNet`, {
  //     username,
  //     password,
  //     branchName,
  //     branchAddress,
  //   });
  // }

  public sendReminder(username: string): Observable<any> {
    return this.http.post(`${API_AUTH}/PasswordReminder`, { username });
  }

  public getEmailConfirmationStatus(username: string): Observable<any> {
    return this.http.get(
      `${API_AUTH}/EmailConfirmedStatus?username=${encodeURIComponent(
        username
      )}`
    );
  }

  resetPassword(username: any, password: any, token: any) {
    return this.http.post(`${API_AUTH}/resetPassword`, {
      username,
      password,
      token,
    });
  }

  confirmEmail(userName: string, token: any) {
    return this.http.post(`${API_AUTH}/confirmEmail`, {
      userName,
      token,
    });
  }

  adminConfirmEmail(
    usernameToConfirm: any,
    adminUsername: string
  ): Observable<any> {
    return this.http.post(`${API_AUTH}/AdminConfirmEmail`, {
      usernameToConfirm,
      adminUsername,
    });
  }

  /* User Service */
  public getByAuth(userId: string): Observable<any> {
    return this.http.get(`${API_USER}/GetByAuth/${userId}`);
  }

  public createUser(request: any): Observable<any> {
    return this.http.post(`${API_USER}`, request);
  }

  public listUser(
    pageNumber: number = 1,
    pageSize: number = 50
  ): Observable<any> {
    return this.http.get(`${API_USER}/list`);
  }

  public getUser(id: number): Observable<any> {
    return this.http.get(`${API_USER}/${id}`);
  }

  public assignRole(username: string, roleName: string): Observable<any> {
    const request = {
      username,
      roleName,
    };
    return this.http.post(`${API_AUTH}/AssignRole`, request);
  }

  public updateUser(request: any): Observable<any> {
    return this.http.put(`${API_USER}/${request.id}`, request);
  }

  // TODO::: create api call
  public updateUserBranch(userId: string, branchId: number): Observable<any> {
    return this.http.put(`${API_USER}/UserBranch`, { userId, branchId });
  }

  public listByLocation(locationId: number): Observable<any> {
    return this.http.get(`${API_USER}/list/${locationId}`);
  }

  /**
   * Clear auth data out
   */
  public async logout(): Promise<void> {
    await this.storage.clear();
    this.#authSubject.next(null);
  }

  /**
   * Set the storage and observable with the auth value 
   */
  public async setSession(auth: SessionUser): Promise<void> {
    await this.storage.setUser(auth);
    this.#authSubject.next(auth);
  }
}
