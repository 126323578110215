import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {mergeMap, delay, retryWhen} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';


export const maxRetries = 3;
export const delayMs = 2000;


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen((error) => {
        return error.pipe(
          mergeMap((error, index) => {
            if (error.status === 401) {
              const snapshot = this.activatedRoute.snapshot;
              this.router.navigate(['/login'], { queryParams: { returnUrl: snapshot.url, replaceUrl: true } });
            // 401 errors are most likely going to be because we have an expired token that we need to refresh.
            // inform user no access
            //return to login
            }
            else if (index < maxRetries && error.status == 500)
            {
              return of(error).pipe(delay(delayMs));
            }

            throw error;
          })
        )
      })
    )
  }
}
