import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { StorageService } from 'src/app/library/storage.service';




@Injectable({
  providedIn: 'root'
})
export class IntroGuard implements CanLoad {

  constructor(private router: Router, private storage: StorageService) { }

  async canLoad(): Promise<boolean> {
    const hasSeenIntro = await this.storage.getIntro();
    if (hasSeenIntro) {
      return true;
    } else {
      this.router.navigateByUrl('/intro', { replaceUrl: true });
      return false;
    }
  }

}
