import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, Platform, MenuController, ViewDidEnter } from '@ionic/angular';
import { ApplicationRole } from './auth/enums/role.enum';
import { AuthService } from './auth/services/auth.service';
import { LoadingService } from './components/loading/loading.service';
import { UserService } from 'src/app/users/services/user.service';
// import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public userMenuItems: any[] = [];
  public firstName = '';
  public lastName = '';
  public isLoading = false;
  IsDesktop: boolean;

  currentVersion: string = '5.2.2';
  latestVersion: any;

  private menuItems$ = [
    {
      title: 'Welcome',
      url: '/welcome',
      icon: 'analytics',
      roles: [
        ApplicationRole.system,
        ApplicationRole.administrator,
        ApplicationRole.user,
      ],
    },
    {
      title: 'Dashboard',
      url: 'dashboard',
      icon: 'speedometer',
      roles: [
        ApplicationRole.system,
        ApplicationRole.administrator,
        ApplicationRole.user,
      ],
    },

    /* admin function */
    {
      title: 'Locations',
      url: 'location-list',
      icon: 'map',
      roles: [ApplicationRole.system, ApplicationRole.administrator],
    },
    {
      title: 'Users',
      url: 'user-list',
      icon: 'people',
      roles: [ApplicationRole.system, ApplicationRole.administrator],
    },
    {
      title: 'Reports',
      url: 'report-list',
      icon: 'pie-chart',
      roles: [ApplicationRole.system, ApplicationRole.administrator],
    },

    /* system function */
    {
      title: 'Tags',
      url: 'tag-list',
      icon: 'pricetags',
      roles: [ApplicationRole.system, ApplicationRole.administrator],
    },
    {
      title: 'Fridges',
      url: 'fridge-list',
      icon: 'snow',
      roles: [ApplicationRole.system, ApplicationRole.administrator],
    },

    /* user function */
    {
      title: 'Deceased',
      url: 'deceased-list',
      icon: 'person',
      roles: [ApplicationRole.system, ApplicationRole.administrator],
    },
    {
      title: 'Removals',
      url: 'removals',
      icon: 'calendar',
      roles: [ApplicationRole.user],
    },
    // { title: 'Profile', url: '/profile', icon: 'cog',
    //   roles: [ApplicationRole.system, ApplicationRole.administrator, ApplicationRole.user] },
    {
      title: 'Help',
      url: '/help',
      icon: 'information-circle',
      roles: [
        ApplicationRole.system,
        ApplicationRole.administrator,
        ApplicationRole.user,
      ],
    },
  ];

  constructor(
    private router: Router,
    private authService: AuthService,
    private menu: MenuController,
    private loader: LoadingService,
    private platform: Platform,
    private readonly userService: UserService,
    private readonly alertController: AlertController,
  ) {
    authService.auth.subscribe((user: any) => {
      if (user) {
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.checkAppVersion();
        if (platform.is('android') || platform.is('ios')){
          this.updateUserVersion(user.userId);
        }
        console.log(user.userId);
        if (user.role) {
          this.userMenuItems = this.menuItems$.filter((item: any) => {

            if (item && item.roles) {
              return item.roles.includes(
                ApplicationRole[user.role.toLowerCase()]
              );
            }
          });
        }
      }
    });

    this.loader.httpProgress().subscribe((status: boolean) => {
      this.isLoading = status;
    });

    if (this.platform.is('desktop')){
      this.IsDesktop = true;
    } else {
      this.IsDesktop = false;
    }


    // this.openLocationSettings();

  }

  async ngOnInit(): Promise<void> {

  }


  // openLocationSettings(){
  //   NativeSettings.open({
  //     optionAndroid: AndroidSettings.ApplicationDetails,
  //     optionIOS: IOSSettings.App
  //   });
  // }

  checkAppVersion() {
    // August 2024 not using API calls
    this.latestVersion = '5.2.2';
    this.userService.GetCurrentVersion().subscribe({
      next: (resp: any) => {
        // console.log(resp.appversion);
        // console.log(resp);
        this.latestVersion = resp.appversion;
        console.log('Latest Version: ' + this.latestVersion)
        console.log('Current Version: ' + this.currentVersion)
        if(this.platform.is("android")) {
          if (this.currentVersion !== this.latestVersion) {
            this.updateVersionPrompt();
          }
        }
      }});

  }

  async updateVersionFromMenu() {
    const downloadLink = 'https://dovesweb.symbiiot.net/assets/Doves_App/Doves_' + this.latestVersion + '.apk';

    //console.log(downloadLink);
    const updateVersion = await this.alertController.create({
      header: 'Update Available',
      message: 'This version of the App is outdated. Do you want to update to the latest version?',
      buttons: [{
        text: 'Update Later',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Update Later');
        }
      },{
        text: 'Update Now',
        handler: () => {
          console.log(this.latestVersion);
          console.log('Update Now');
          window.open(downloadLink);
        }
      }]
    });

    await updateVersion.present();
  }

  async updateVersionPrompt() {

    const downloadLink = 'https://dovesweb.cloudco.technology/assets/Doves_App/Doves_' + this.latestVersion + '.apk';

    console.log('Download Link: ', downloadLink);
    const updateVersion = await this.alertController.create({
      header: 'Update Available',
      message: 'This version of the App is outdated. Please update to the latest version.',
      buttons: [{
        text: 'Update Later',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Update Later');
        }
      },{
        text: 'Update Now',
        handler: () => {
          console.log('Latest Version: ', this.latestVersion);
          console.log('Update Now');
          window.open(downloadLink);
        }
      }]
    });

    await updateVersion.present();
  }

  updateUserVersion(userId) {
    const request = {
      userId: userId,
      appversion: this.currentVersion
    };
    this.userService.UpdateUserVersion(request).subscribe((response: any) => {
      console.log(response);
    });;
  }

  public async openItem(url: any): Promise<void> {
    this.router.navigate([`${url}`], { replaceUrl: true });
    await this.menu.close();
  }

  public async logout(): Promise<void> {
    this.authService.logout();
    await this.menu.close();
    this.router.navigate(['/intro'], { replaceUrl: true });
    this.menu.enable(false);
  }
}
