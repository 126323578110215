import { Component } from '@angular/core';
import { AuthService, SessionUser } from 'src/app/auth/services/auth.service';
import { ApplicationRole } from 'src/app/auth/enums/role.enum';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent   {
  public showTabs = false;
  public removalsCount = 0;
  public adminRole = false;

  constructor(
    readonly session: AuthService
  ) {
    session.auth.subscribe((user: SessionUser) =>{
      const viewerRoleDescription = ApplicationRole[ApplicationRole.viewer]?.toLowerCase();
      const adminRoleDescription = ApplicationRole[ApplicationRole.administrator]?.toLowerCase();
      const systemUserDescription = ApplicationRole[ApplicationRole.system]?.toLowerCase();

      this.adminRole = user?.role.toLowerCase() === adminRoleDescription
      ||  user?.role.toLowerCase() === systemUserDescription;
      if (user && user.role && user.isAuthenticated && user?.role.toLowerCase() !== viewerRoleDescription) { // make sure not a viewer
        this.showTabs = true;
      }
      else{
        this.showTabs = false;
      }

      if (!user || !user.role) {
        this.showTabs = false;
      }
    });

   }
}
