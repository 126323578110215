import { Injectable } from '@angular/core';
import { Toast } from '@capacitor/toast';


@Injectable({
  providedIn: 'root'
})
export class ToastService {

  public async info(msg: string) {
    await Toast.show({
      text: msg,
      duration: 'long',
      position: 'top'
    });
  }

  public async error(msg: string) {
    await Toast.show({
      text: msg,
      duration: 'long',
      position: 'top'
    });
  }

  public async warning(msg: string) {
    await Toast.show({
      text: msg,
      duration: 'long',
      position: 'top'
    });
  }


  public async success(msg: string) {
    await Toast.show({
      text: msg,
      duration: 'long',
      position: 'top'
    });
  }
}
