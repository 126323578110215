import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { request } from 'http';


const USER_API = `${environment.apiUrl}user`;



@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private readonly http: HttpClient) {  }

  public getByAuth(userId: string): Observable<any> {
    return this.http.get(`${USER_API}/GetByAuth/${userId}`);
  }

  //Add call to get UserId for Assigned User
  public getUserByUserId(userid: string): Observable<any> {
    return this.http.get(`${USER_API}/GetByUserId?userId=${userid}`);
  }

  public createUser(request: any): Observable<any> {
    return this.http.post(`${USER_API}`, request);
  }

  public listUser(pageNumber: number = 1, pageSize: number = 50): Observable<any> {
    return this.http.get(`${USER_API}/list`);
  }

  public getUser(id: number): Observable<any> {
    return this.http.get(`${USER_API}/${id}`);
  }

  public updateUser(id: number, request: any): Observable<any> {
    return this.http.put(`${USER_API}/${id}`, request);
  }

  public listByLocation(locationId: number): Observable<any> {
    return this.http.get(`${USER_API}/list/${locationId}`);
  }

  public listForRole(role: string): Observable<any> {
    return this.http.get(`${USER_API}/ListForRole/${role}`);
  }

  // public GetCurrentVersion(): Observable<any> {
  //   const request = {
  //     withCredentials: true
  //   }
  //   return this.http.get(`${USER_API}/GetCurrentVersion`, request);
  // }

  public GetCurrentVersion(): Observable<any> {
      return this.http.get(`${USER_API}/GetCurrentVersion`);
    }

  public UpdateUserVersion(request: any): Observable<any> {
    return this.http.put(`${USER_API}/UpdateUserVersion`, request);
  }
  
  // {
  //   "userId": "string",
  //   "appversion": "string"
  // }
}
