import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayPipe',
  pure: false
})
export class DayPipe implements PipeTransform {

  transform(value: number): any {
    const date = new Date(value);
    return date.getDate().toString();
  }
}
