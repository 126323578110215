import { TabsComponent } from './tabs/tabs.component';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';


@NgModule({
  imports: [
    IonicModule //add ionic module here
  ],
  declarations: [
  TabsComponent
  ],
  exports: [
    TabsComponent
  ]
})

export class ComponentModule { }
