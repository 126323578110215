import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { SessionUser } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public constructor() {}

  /**
   *  Gets the value for the intro screen - if it has been seen or not
   *
   * @returns
   */
  public async getIntro(): Promise<string | null> {
    const item = await Preferences.get({ key: environment.introKey });

    if (item === null || item.value == null) {
      return null;
    }
    return JSON.parse(item.value);
  }

  /**
   *
   *
   * @param value
   */
  public async setIntro(value: boolean): Promise<void> {
    await Preferences.set({
      key: environment.introKey,
      value: JSON.stringify(value),
    });
  }

  /**
   * Sets the auth storage
   *
   * @param value
   */
  public async setUser(auth: SessionUser): Promise<void> {
    const d1 = new Date();
    const d2 = new Date(d1);
    d2.setMinutes(d1.getMinutes() + 1440);
    auth.expiryDate = d2;

    await Preferences.set({
      key: environment.userKey,
      value: JSON.stringify(auth),
    });
  }

  /**
   *
   * @returns
   */
  public async getUser(): Promise<SessionUser | null> {
    const item = await Preferences.get({ key: environment.userKey });

    if (
      item === undefined ||
      item == null ||
      item.value === undefined ||
      item.value == null
    ) {
      return null;
    }

    const authStorage = JSON.parse(item.value);

    if (new Date() > authStorage.expiryDate) {
      this.removeUser();
      return null;
    }

    return JSON.parse(item.value);
  }

  /**
   * Remove the auth storage item
   */
  async removeUser(): Promise<void> {
    await Preferences.remove({ key: environment.userKey });
  }

  /**
   * Clear out all the storage items for the application
   */
  public async clear(): Promise<void> {
    await Preferences.clear();
  }
}
